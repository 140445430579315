import { useState, useRef, useEffect, useCallback } from 'react';
import { useInterval } from '../../utils/useInterval';
import { fetchAndBypassCORS } from '../../utils/DataFetcher';
import { logger } from '../../utils/logger';
export const MSCI_TAIWAN_ID_AND_WEIGHT = require('./msci_stocks.json');

export const useMsciStocks = () => {
  const [data, setData] = useState();
  const dataRef = useRef();

  const updateData = useCallback(async () => {
    try {
      const response = await getData();
      const cleanData = transform(response.msgArray);
      if (dataRef.current && typeof cleanData !== 'undefined') {
        const updatedData = generateDataForUpdate(cleanData);
        dataRef.current = updatedData;
        setData(updatedData);
      } else if (typeof cleanData !== 'undefined') {
        // When run it for the first time, simply use the fetched data
        dataRef.current = cleanData;
        setData(cleanData);
      }
    } catch (error) {
      logger.error('MSCI data error: ', error.message);
      dataRef.current = [];
      setData([]);
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) updateData();
    return () => {
      isMounted = false;
    };
  }, [updateData]);

  const update_interval = 10000;
  useInterval(() => updateData(), update_interval);

  function generateDataForUpdate(newData) {
    return dataRef.current.map((item) => {
      const target = newData.find((obj) => obj.id === item.id);
      return {
        ...item,
        current_price: !isNaN(target.current_price)
          ? target.current_price
          : item.current_price,
      };
    });
  }

  return data;
};

export function transform(rawData) {
  return rawData
    .filter((item) => item.n)
    .map((item, index) => ({
      name: item.n,
      id: item.c,
      current_price: item.z,
      last_price: item.y,
      weight: MSCI_TAIWAN_ID_AND_WEIGHT[index].weight,
    }));
}

export async function getData() {
  try {
    return await fetchAndBypassCORS(generateApiUrl());
  } catch (error) {
    logger.error('TWSE server error.');
  }
}

function generateApiUrl() {
  const TWSE_BASE_URL =
    'https://mis.twse.com.tw/stock/api/getStockInfo.jsp?json=1&delay=0&ex_ch=';
  const stringsToAppend = MSCI_TAIWAN_ID_AND_WEIGHT.map(
    (item) => 'tse_' + item.id + '.tw|'
  ).join('');
  return TWSE_BASE_URL + stringsToAppend;
}
