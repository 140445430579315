import React from 'react';
import PropTypes from 'prop-types';

const LoadSpinner = () => {
  return (
    <div className="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

const Card = ({ children }) => {
  return <li className="flex-card-listitem">{children}</li>;
};

Card.propTypes = {
  children: PropTypes.node,
};

const Collapsible = ({ children }) => {
  return (
    <div className="wrap-collapsible">
      <input id="collapsible" className="toggle" type="checkbox" />
      <label htmlFor="collapsible" className="lbl-toggle">
        這是什麼？
      </label>
      <div className="collapsible-content">
        <div className="content-inner">{children}</div>
      </div>
    </div>
  );
};

Collapsible.propTypes = {
  children: PropTypes.node,
};

export { LoadSpinner, Card, Collapsible };
