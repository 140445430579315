import React from 'react';
import { UseYahooFinanceData } from './useYahooFinanceData';
import { UseNikkeiData } from './useNikkeiData';
import { UseInvestingData } from './useInvestingData';
import { LoadSpinner } from '../CommonUIs';
import { ChartTitle } from './ChartTitle';
import { LineChart } from './LineChart';
import { Notification } from '../Notification';
import PropTypes from 'prop-types';
import logger from '../../utils/logger';

const isTimeBetween = (time, startTime, endTime) => {
  const timeValue = time.valueOf();
  const start = new Date(startTime).valueOf();
  const end = new Date(endTime).valueOf();
  if (timeValue >= start && timeValue <= end) {
    return true;
  }
  return false;
};

export const GlobalIndicesLineChart = ({
  symbol,
  title,
  source,
  tradingPeriods,
}) => {
  let data;
  switch (source) {
    case 'yahoo':
      data = UseYahooFinanceData(symbol);
      break;
    case 'nikkei':
      data = UseNikkeiData(symbol);
      break;
    case 'investing':
      data = UseInvestingData(symbol, tradingPeriods);
      break;
    default:
      logger.info('You need to specify the source.');
  }
  if (!data) return <LoadSpinner />;
  // if (data) logger.log(data);

  return (
    <div className="line-chart card flex-card">
      <ChartTitle title={title} data={data} />
      <LineChart data={data} />
      {data.tradingPeriods &&
        !isTimeBetween(
          new Date(),
          data.tradingPeriods.start,
          data.tradingPeriods.end
        ) && <Notification status="info" text="非交易時段" />}
    </div>
  );
};

GlobalIndicesLineChart.propTypes = {
  symbol: PropTypes.string,
  title: PropTypes.string,
  source: PropTypes.string,
  tradingPeriods: PropTypes.object,
};
