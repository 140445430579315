import React from 'react';
import PropTypes from 'prop-types';

export const ChartTitle = ({ title, data }) => {
  // const current_price = data.lineData.slice(-1)[0]["quote"];
  let index = data.lineData.length;
  while (index-- && !data.lineData[index].quote);
  const current_price = data.lineData[index].quote;

  const change = ((current_price - data.baseline) / data.baseline) * 100;
  return (
    <h3 className={`chart-title inline ${change >= 0 ? 'rising' : 'falling'}`}>
      {title} {current_price ? current_price.toFixed(2) : '無最新價'}
      <span
        className={`sm-txt-on-bg ${change >= 0 ? 'bullish-bg' : 'bearish-bg'}`}
      >
        {change >= 0 ? `+${change.toFixed(2)}` : change.toFixed(2)}%
      </span>
    </h3>
  );
};

ChartTitle.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
};
