import { useState, useEffect, useCallback } from 'react';
import { useInterval } from '../../utils/useInterval';
import { fetchAndBypassCORS } from '../../utils/DataFetcher';
import logger from '../../utils/logger';

function transform(data, tradingPeriodsStr) {
  const quotesToday = data[0].data.filter(
    (d) => d[0] > new Date().setHours(0).valueOf()
  );
  const lineData = quotesToday.length
    ? quotesToday.map((d) => ({ date: d[0], quote: d[4] }))
    : data[0].data.map((d) => ({ date: d[0], quote: d[4] }));
  const baseline = data[1].data.slice(-2)[0][4];

  const start = new Date();
  start.setHours(
    tradingPeriodsStr.start.split(':')[0],
    tradingPeriodsStr.start.split(':')[1],
    tradingPeriodsStr.start.split(':')[2]
  );
  const end = new Date();
  end.setHours(
    tradingPeriodsStr.end.split(':')[0],
    tradingPeriodsStr.end.split(':')[1],
    tradingPeriodsStr.end.split(':')[2]
  );

  const tradingPeriods = {
    start: start,
    end:
      end.valueOf() > start.valueOf()
        ? end
        : new Date(end.valueOf() + 86400000),
  };
  return { lineData, baseline, tradingPeriods };
}

export const UseInvestingData = (symbol, tradingPeriods) => {
  const [data, setData] = useState(null);
  // if (data) logger.info(data);

  const API_URL = `https://api.investing.com/api/financialdata/${symbol}/historical/chart/?period=P1D&interval=PT5M&pointscount=120`;
  const INFO_API_URL = `https://api.investing.com/api/financialdata/${symbol}/historical/chart/?interval=P1D&pointscount=60`;

  const updateData = useCallback(async () => {
    const fetcher = (api) =>
      fetchAndBypassCORS(api).catch((err) => logger.error(err));
    Promise.all([fetcher(API_URL), fetcher(INFO_API_URL)]).then((values) =>
      setData(transform(values, tradingPeriods))
    );
  }, [API_URL, INFO_API_URL, tradingPeriods]);

  // Fetch once while the component starts
  useEffect(() => {
    let isMounted = true;
    if (isMounted) updateData();
    return () => {
      isMounted = false;
    };
  }, [updateData]);

  // And then run every minute
  const delay = 60000;
  useInterval(
    () =>
      // Make the request here
      updateData(),
    delay
  );

  return data;
};
