import 'react-app-polyfill/ie9';
import React, { useState, useEffect } from 'react';
import './App.css';
import { cookieExists, setCookie, findCookie } from './utils/useCookies';

import { NavBar } from './elements/NavBar';
import { VolumeView } from './views/VolumeView';
import { IndicesView } from './views/IndicesView';
import { MoneyView } from './views/MoneyView';
import { MSCIStocksHeatMap } from './elements/HeatMap/HeatMap';

import PullToRefresh from 'pulltorefreshjs';
import ReactDOMServer from 'react-dom/server';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const App = () => {
  const [view, setView] = useState('volume');
  useEffect(() => {
    initializePullToRefresh();

    if (cookieExists('view')) {
      setView(findCookie('view'));
    } else {
      setCookie('view', 'indices');
    }
  }, []);
  return (
    <div className="App">
      <NavBar view={view} setView={setView} />
      <div id="charts-area" className="flex-split">
        {
          {
            indices: <IndicesView />,
            heatmap: <MSCIStocksHeatMap solo={true} />,
            volume: <VolumeView />,
            money: <MoneyView />,
          }[view]
        }
      </div>
    </div>
  );
};

function initializePullToRefresh() {
  PullToRefresh.init({
    mainElement: 'body',
    onRefresh() {
      window.location.reload();
    },
    iconArrow: ReactDOMServer.renderToString(
      <FontAwesomeIcon icon={faSyncAlt} color="white" />
    ),
    iconRefreshing: ReactDOMServer.renderToString(
      <FontAwesomeIcon icon={faSyncAlt} color="white" spin={true} />
    ),
  });
  return () => {
    PullToRefresh.destroyAll();
  };
}

export default App;
