import React from 'react';
import { LoadSpinner } from '../CommonUIs';
import { useMsciStocks } from './useMsciStocks';
import { Notification } from '../Notification';
import { GoogleTreeMap } from './GoogleTreeMap';
import PropTypes from 'prop-types';

export const MSCIStocksHeatMap = ({ solo }) => {
  const data = useMsciStocks();
  if (!data) return <LoadSpinner />;

  const treeMapData = generateDataForGoogleTreeMap(data);

  return (
    <div id="heatmap" className={solo ? 'solo' : ''}>
      {!treeMapData && (
        <Notification status="error" text="資料來源可能有誤，請稍候。" />
      )}
      <GoogleTreeMap data={treeMapData} />
    </div>
  );
};

MSCIStocksHeatMap.propTypes = {
  solo: PropTypes.bool,
};

export function generateDataForGoogleTreeMap(data) {
  let headers = [
    ['Item', 'Parent', 'Weight', 'Value'],
    ['MSCI Taiwan', null, 0, 0],
  ];

  const table = data.map((d) => {
    let percent_change, display_info;
    if (!d.current_price || isNaN(d.current_price)) {
      percent_change = 0;
      display_info = d.name + '　尚無資料';
    } else {
      percent_change = (
        ((d.current_price - d.last_price) / d.last_price) *
        100
      ).toFixed(2);
      display_info = d.name + ' ' + percent_change + '%';
    }
    return [
      display_info,
      'MSCI Taiwan',
      Math.round(d.weight), // Google Charts僅支援整數百分比
      Number(percent_change),
    ];
  });

  return [...headers, ...table];
}
