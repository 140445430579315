import React, { useMemo } from 'react';
import { pointer, bisectCenter } from 'd3';
import styles from './LineChart.module.css';
import PropTypes from 'prop-types';

export const RectOverlay = ({
  data,
  lineGenerator,
  onHover,
  innerWidth,
  innerHeight,
}) => {
  return useMemo(() => {
    const points = data.map((d) => lineGenerator.x()(d));
    return (
      <g className={styles.captureArea}>
        <rect
          width={innerWidth}
          height={innerHeight}
          onPointerEnter={(event) => {
            const index = bisectCenter(points, pointer(event)[0]);
            onHover(data[index].quote && data[index]);
          }}
          onTouchStart={(event) => {
            event.preventDefault();
            const index = bisectCenter(points, pointer(event)[0]);
            onHover(data[index].quote && data[index]);
          }}
          onPointerMove={(event) => {
            const index = bisectCenter(points, pointer(event)[0]);
            onHover(data[index].quote && data[index]);
          }}
          onPointerLeave={() => {
            onHover(null);
          }}
        />
      </g>
    );
  }, [data, lineGenerator, innerHeight, innerWidth, onHover]);
};

RectOverlay.propTypes = {
  data: PropTypes.array,
  innerHeight: PropTypes.number,
  innerWidth: PropTypes.number,
  lineGenerator: PropTypes.func,
  onHover: PropTypes.func,
};
