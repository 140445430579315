import React, { useRef, useEffect } from 'react';
import { select, axisBottom } from 'd3';
import styles from './LineChart.module.css';
import PropTypes from 'prop-types';

export const AxisBottom = ({
  xScale,
  innerHeight,
  tickFormat,
  tickCount = 6,
  tickOffset = 3,
}) => {
  const ref = useRef();
  useEffect(() => {
    const xAxisG = select(ref.current);
    const xAxis = axisBottom(xScale)
      .ticks(tickCount, tickFormat)
      .tickSize(-innerHeight)
      .tickPadding(tickOffset);
    xAxisG.call(xAxis).call((g) => g.select('.domain').remove());
  }, [xScale, innerHeight, tickFormat, tickOffset, tickCount]);
  return (
    <g
      ref={ref}
      className={styles.tick}
      transform={`translate(0, ${innerHeight})`}
    />
  );
};

AxisBottom.propTypes = {
  xScale: PropTypes.func,
  innerHeight: PropTypes.number,
  tickFormat: PropTypes.func,
  tickCount: PropTypes.number,
  tickOffset: PropTypes.number,
};
