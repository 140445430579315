import { useState, useEffect } from 'react';
import logger from './logger';

// HELPER FUNCTIONS
const CheckError = (response) => {
  if (response.statusCode === 200) {
    return response.json();
  } else {
    response.json().then((msg) => logger.error(msg.error));
  }
};

// Generic hook to fetch data v1
const useFetch = (initialUrl, initialData, transformFn, timeInterval) => {
  const [data, setData] = useState(initialData);
  const [url, setUrl] = useState(initialUrl);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetch(url).then(CheckError);
        setData((transformFn && transformFn(data)) || data);
      } catch (error) {
        setIsError(true);
        logger.error(error);
      }
      setIsLoading(false);
      setIsError(false);
    };
    fetchData();
    if (timeInterval) {
      const interval = setInterval(() => {
        fetchData();
      }, timeInterval);
      return () => clearInterval(interval);
    }
  }, [url, transformFn, timeInterval]);
  return [{ data, isLoading, isError }, setUrl];
};

async function fetchAndBypassCORS(url) {
  url = `https://2sgx66cj6f.execute-api.ap-northeast-1.amazonaws.com/default/bypassCORS?url=${url}`;
  try {
    const response = await fetch(url);
    return response.json();
  } catch (e) {
    logger.error(e.message);
  }
}

export { useFetch, CheckError, fetchAndBypassCORS };
