import React from 'react';
import styles from './LineChart.module.css';
import PropTypes from 'prop-types';

export const XMarkerLine = ({ value, xScale, innerHeight }) => {
  const markerLineX = xScale(value);
  const markerLineY1 = 0;
  const markerLineY2 = innerHeight;
  return (
    <line
      className={styles.markerLine}
      x1={markerLineX}
      y1={markerLineY1}
      x2={markerLineX}
      y2={markerLineY2}
    />
  );
};

XMarkerLine.propTypes = {
  value: PropTypes.number,
  xScale: PropTypes.func,
  innerHeight: PropTypes.number,
};
