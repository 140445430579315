import React from 'react';
import { GlobalIndicesLineChart } from '../elements/GlobalIndicesLineChart';
import PropTypes from 'prop-types';
import { Card } from '../elements/CommonUIs';
import { MSCIStocksHeatMap } from '../elements/HeatMap/HeatMap';

// Portfolios from different sources
const morning_portfolio = [
  { symbol: '^KS11', title: '韓國延遲', source: 'yahoo' },
  // {
  //   symbol: '37426',
  //   title: '韓國',
  //   source: 'investing',
  //   tradingPeriods: { start: '08:00:00', end: '14:30:00' },
  // },
  // { symbol: 'N101/T', title: '日本', source: 'nikkei' },
  { symbol: '^N225', title: '日本延遲', source: 'yahoo' },
  { symbol: '^HSI', title: '恆生延遲', source: 'yahoo' },
  { symbol: '000001.SS', title: '上證延遲', source: 'yahoo' },
  // {
  //   symbol: '179',
  //   title: '恆生',
  //   source: 'investing',
  //   tradingPeriods: { start: '09:30:00', end: '16:00:00' },
  // },
  // {
  //   symbol: '40820',
  //   title: '上證',
  //   source: 'investing',
  //   tradingPeriods: { start: '09:30:00', end: '15:00:00' },
  // },
  { symbol: 'YM=F', title: '小道瓊延遲', source: 'yahoo' },
  { symbol: 'USDTWD=X', title: '台幣', source: 'yahoo' },
];

const night_portfolio = [
  // {
  //   symbol: '27',
  //   title: '英國',
  //   source: 'investing',
  //   tradingPeriods: {
  //     start: '16:00:00',
  //     end: '01:00:00',
  //   },
  // },
  // {
  //   symbol: '172',
  //   title: '德國',
  //   source: 'investing',
  //   tradingPeriods: {
  //     start: '16:00:00',
  //     end: '01:00:00',
  //   },
  // },
  { symbol: '^FTSE', title: '英國延遲', source: 'yahoo' },
  { symbol: '^GDAXI', title: '德國', source: 'yahoo' },
  { symbol: '^IXIC', title: '那指', source: 'yahoo' },
  { symbol: '^SOX', title: '費半', source: 'yahoo' },
  { symbol: '^DJI', title: '道瓊', source: 'yahoo' },
  { symbol: 'USDTWD=X', title: '台幣', source: 'yahoo' },
];

const IndicesView = ({ view }) => {
  const portfolio =
    currentHour >= 8 && currentHour < 16 ? morning_portfolio : night_portfolio;
  return (
    <>
      <div id="indices">
        <ul id="global-indices" className="flex-card-list">
          {portfolio.map((item) => (
            <Card key={item.symbol}>
              <GlobalIndicesLineChart {...item} />
            </Card>
          ))}
        </ul>
      </div>
      <MSCIStocksHeatMap view={view} solo={false} />
    </>
  );
};
IndicesView.propTypes = {
  view: PropTypes.string,
};

const currentHour = new Date()
  .toLocaleTimeString('zh-TW', { timeZone: 'Asia/Taipei', hour12: false })
  .slice(0, 2);

export { IndicesView };
