import { useState, useEffect, useCallback } from 'react';
import { useInterval } from '../../utils/useInterval';
import { fetchAndBypassCORS } from '../../utils/DataFetcher';
import logger from '../../utils/logger';

function transform(data) {
  const lineData = data['RESULT']['data_lists'][0]['data'].map((d) => ({
    date: d[0],
    quote: d[1],
  }));
  const baseline = Number(data.RESULT.data_lists[0].dsp);
  const tradingPeriods = {
    start: data.RESULT.xrange[0],
    end: data.RESULT.xrange[1],
  };
  return { lineData, baseline, tradingPeriods };
}

export const UseNikkeiData = (symbol) => {
  const [data, setData] = useState(null);
  // if (data) logger.info(data);

  const API_URL = `https://www.nikkei.com/async/async.do/?ae=JO_MM_INDEXES_CHART_ONE&chartType=one_indexes_l&scode=${encodeURIComponent(
    symbol
  )}&JSON=true`;

  const updateData = useCallback(async () => {
    fetchAndBypassCORS(API_URL)
      .then((data) => setData(transform(data)))
      .catch((err) => {
        logger.error(err);
      });
  }, [API_URL]);

  // Fetch once while the component starts
  useEffect(() => {
    let isMounted = true;
    if (isMounted) updateData();
    return () => {
      isMounted = false;
    };
  }, [updateData]);

  // And then run every minute
  const delay = 60000;
  useInterval(
    () =>
      // Make the request here
      updateData(),
    delay
  );

  return data;
};
