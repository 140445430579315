import { useState, useEffect } from 'react';
import { csvParse } from 'd3';
import { fetchAndBypassCORS } from '../../utils/DataFetcher';

export const useCBCData = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    let isMounted = true;
    const API =
      'https://www.cbc.gov.tw/public/data/OpenData/%E7%B6%93%E7%A0%94%E8%99%95/EF15M01.csv?downloadFile=true';

    fetchAndBypassCORS(API)
      .then((rawData) => csvParse(rawData))
      .then((data) => {
        if (isMounted) setData(data);
      });

    return () => {
      isMounted = false;
    };
  }, []);
  return data;
};
