import React, { useState, useMemo, useCallback } from 'react';
import { scaleLinear, scaleTime, line, timeFormat, extent, format } from 'd3';
import { AxisBottom } from './AxisBottom';
import { AxisLeft } from './AxisLeft';
import { Tooltip } from './Tooltip';
import { YMarkerLine } from './YMarkerLine';
import { RectOverlay } from './RectOverlay';
import styles from './LineChart.module.css';
import PropTypes from 'prop-types';
// import { logger } from '../../utils/logger';

const width =
  window.innerWidth < 1024
    ? window.innerWidth * 0.45
    : window.innerWidth * 0.22;
const height = window.innerHeight * 0.16;
const margin = { top: 5, right: 15, bottom: 12, left: 36 };

const xValue = (d) => d.date;
const xAxisTickFormat = timeFormat('%H:%M');
const xAxisLabelOffset = 5;
const axisLabelCount = 3;

const yValue = (d) => d.quote;
// const yAxisLabel = "New cases";
// const yAxisLabelOffset = 60;
const yAxisTickFormat = format(',.0f');
const yAxisLabelOffset = 5;

export const LineChart = ({ data }) => {
  // if (data) logger.info(data);
  const [activeData, setActiveData] = useState(null);

  const { lineData, baseline } = data;

  const innerHeight = height - margin.top - margin.bottom;
  const innerWidth = width - margin.left - margin.right;

  const xScale = useMemo(
    () =>
      scaleTime()
        // Domain is an array of actual dates
        // d3.extent(iterable[, accessor]) returns the [max, min] of iterable
        .domain(extent(lineData, xValue))
        // Range is where the data is shown in pixels, starts from 0 to chart's width
        .range([0, innerWidth]),
    [lineData, innerWidth]
  );

  // Y is countries is categorical, band scale is for ordinal or categorical dimension
  // Include baseline price while deciding the range of Y axis
  const yExtent = useMemo(
    () => [...lineData, { quote: baseline }],
    [baseline, lineData]
  );
  const yScale = useMemo(
    () => scaleLinear().domain(extent(yExtent, yValue)).range([innerHeight, 0]),
    [yExtent, innerHeight]
  );

  const handleVoronoiHover = useCallback(setActiveData, [setActiveData]);

  const lineGenerator = useMemo(
    () =>
      line()
        .x((d) => xScale(xValue(d)))
        .y((d) => yScale(yValue(d)))
        .defined((d) => yScale(yValue(d)) !== undefined),
    [xScale, yScale]
  );

  return (
    <>
      <svg viewBox={`0 0 ${width} ${height}`} preserveAspectRatio="xMinYMid">
        {/* <svg style={{ height: "100%", width: "100%" }}> */}
        {/* Adds margin to left and top  */}
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          <AxisBottom
            innerHeight={innerHeight}
            xScale={xScale}
            tickFormat={xAxisTickFormat}
            tickOffset={xAxisLabelOffset}
            tickCount={axisLabelCount}
          />

          <AxisLeft
            innerWidth={innerWidth}
            yScale={yScale}
            tickOffset={yAxisLabelOffset}
            tickFormat={yAxisTickFormat}
            tickCount={axisLabelCount}
          />

          {/* <text */}
          {/*   className={styles.axisLabel} */}
          {/*   textAnchor="middle" */}
          {/*   transform={`translate(${-yAxisLabelOffset}, ${ */}
          {/*     innerHeight / 2 */}
          {/*   }) rotate(-90)`} */}
          {/* > */}
          {/*   {yAxisLabel} */}
          {/* </text> */}

          <g className={styles.marks}>
            <path d={lineGenerator(lineData)} />
          </g>

          <YMarkerLine
            value={baseline}
            yScale={yScale}
            innerWidth={innerWidth}
          />

          {activeData ? (
            <Tooltip
              lineGenerator={lineGenerator}
              activeData={activeData}
              xScale={xScale}
              xTooltipFormat={xAxisTickFormat}
              innerHeight={innerHeight}
            />
          ) : null}

          <RectOverlay
            onHover={handleVoronoiHover}
            data={lineData}
            lineGenerator={lineGenerator}
            innerWidth={innerWidth}
            innerHeight={innerHeight}
          />
        </g>
      </svg>
    </>
  );
};

LineChart.propTypes = {
  data: PropTypes.object,
};
