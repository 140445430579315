import { useState, useEffect, useCallback } from 'react';
import { useInterval } from '../../utils/useInterval';
import { fetchAndBypassCORS } from '../../utils/DataFetcher';
import logger from '../../utils/logger';

function transform(data) {
  const lineData = data['chart']['result'][0]['timestamp'].map((t, i) => ({
    date: t * 1000,
    quote: data['chart']['result'][0]['indicators']['quote'][0]['close'][i],
  }));
  const baseline = data['chart']['result'][0]['meta']['previousClose'];
  const tradingPeriods = {
    start: data.chart.result[0].meta.currentTradingPeriod.regular.start * 1000,
    end: data.chart.result[0].meta.currentTradingPeriod.regular.end * 1000,
  };
  return { lineData, baseline, tradingPeriods };
}

export const UseYahooFinanceData = (symbol) => {
  const [data, setData] = useState(null);
  // if (data) logger.info(data);

  const API_URL = `https://partner-query.finance.yahoo.com/v8/finance/chart/${symbol}?range=1d&interval=1m`;

  const updateData = useCallback(
    async () =>
      fetchAndBypassCORS(API_URL)
        .then((data) => setData(transform(data)))
        .catch((err) => {
          logger.error(err);
        }),
    [API_URL]
  );

  // Fetch once while the component starts
  useEffect(() => {
    let isMounted = true;
    if (isMounted) updateData();
    return () => {
      isMounted = false;
    };
  }, [updateData]);

  // And then run every minute
  const delay = 60000;
  useInterval(
    () =>
      // Make the request here
      updateData(),
    delay
  );

  return data;
};
