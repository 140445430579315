import React, { useRef, useEffect } from 'react';
import { select, axisLeft } from 'd3';
import styles from './LineChart.module.css';
import PropTypes from 'prop-types';

export const AxisLeft = ({
  yScale,
  innerWidth,
  tickFormat,
  tickCount = 6,
  tickSizeOuter = 0,
  tickOffset = 3,
}) => {
  const ref = useRef();
  useEffect(() => {
    const yAxisG = select(ref.current);
    const yAxis = axisLeft(yScale)
      .ticks(tickCount, tickFormat)
      .tickSize(innerWidth)
      .tickSizeOuter(tickSizeOuter)
      .tickPadding(tickOffset);
    yAxisG.call(yAxis).call((g) => g.select('.domain').remove());
  }, [yScale, innerWidth, tickFormat, tickOffset, tickCount, tickSizeOuter]);
  return (
    <g
      ref={ref}
      className={styles.tick}
      transform={`translate(${innerWidth}, 0)`}
    />
  );
};

AxisLeft.propTypes = {
  yScale: PropTypes.func,
  innerWidth: PropTypes.number,
  tickFormat: PropTypes.func,
  tickCount: PropTypes.number,
  tickSizeOuter: PropTypes.number,
  tickOffset: PropTypes.number,
};
