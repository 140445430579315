import React from 'react';
import { M1BvsM2Chart } from '../elements/M1BvsM2Chart/index';
import { CentralBankBlalanceSheetsLineChart } from '../elements/CentralBankBalenceSheetsLineChart';
import { Collapsible } from '../elements/CommonUIs';

const MoneyView = () => {
  return (
    <>
      <div className="volume-charts">
        <CentralBankBlalanceSheetsLineChart />
      </div>
      <div className="volume-charts">
        <div className="quarter-page-chart card">
          <M1BvsM2Chart />
          <M1BM2Info />
        </div>
      </div>
    </>
  );
};

const M1BM2Info = () => {
  return (
    <Collapsible>
      <h3>M1B = M1A + 活期儲蓄存款</h3>
      <p>
        M1A = 通貨淨額（社會大眾手上的現金）+ 支票存款 +
        活期存款。相較於M1A，M1B的流動性較低，代表國人手邊隨時可動用的資金，組成包括M1A和活期儲蓄存款，M1B年增率被視為「股市資金動能指標」。
      </p>
      <h3>M2 = M1B + 準貨幣（定期存款 + 外匯存款 + 郵政儲金）</h3>
      <p>
        M2屬流動性更低的金融資產，組成包括M1B，還包含作為價值儲存目的的準貨幣，包含定期存款、外匯存款、外國人新台幣存款、郵政儲金及貨幣市場共同基金等，代表「整體市場的資金」。
      </p>
    </Collapsible>
  );
};

export { MoneyView };
