import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import { LoadSpinner } from '../CommonUIs';

export const GoogleStackedAreaChart = ({ title, data }) => (
  <Chart
    width="100%"
    height="38vh"
    chartType="AreaChart"
    loader={<LoadSpinner />}
    data={data}
    options={{
      isStacked: true,
      title: title,
      titleTextStyle: {
        fontSize: 18,
        color: 'white',
      },
      backgroundColor: 'transparent',
      hAxis: {
        textStyle: { color: '#fefdfe' },
        textPosition: 'in',
        maxTextLines: 1,
        maxAlternation: 1,
      },
      vAxis: {
        textStyle: { color: '#FFFFFF' },
        gridlines: { count: 5, color: '#534053' },
        minorGridlines: { count: 0 },
        baseline: 0,
        baselineColor: '#534053',
        textPosition: 'in',
        format: 'short',
      },
      legend: { position: 'in', textStyle: { color: '#fefdfe' } },
      colors: ['#1a7aff', '#43c74f', '#fb9500', '#fa2a54', '#ad51e2'],
      lineWidth: 1.5,
      chartArea: {
        left: 0,
        right: 0,
        top: 40,
        bottom: 0,
      },
    }}
    chartEvents={[
      {
        eventName: 'error',
        callback: (chart) => {
          handleChartError(chart);
        },
      },
    ]}
  />
);

GoogleStackedAreaChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
};

function handleChartError(chart) {
  let error_id = chart.eventArgs[0].id;
  const errors = chart.google.visualization.errors;
  let container = errors.getContainer(error_id);
  errors.removeError(error_id);
  errors.removeAll(container);
  errors.addError(container, `未開盤`, '', {
    style: 'background: #414455; font-size: 16px; font-weight: bold;',
  });
}
