import React from 'react';
import styles from './LineChart.module.css';
import PropTypes from 'prop-types';

export const YMarkerLine = ({ value, yScale, innerWidth }) => {
  const markerLineY = yScale(value);
  const markerLineX1 = 0;
  const markerLineX2 = innerWidth;
  return (
    <line
      className={styles.baseLine}
      x1={markerLineX1}
      y1={markerLineY}
      x2={markerLineX2}
      y2={markerLineY}
    />
  );
};

YMarkerLine.propTypes = {
  value: PropTypes.number,
  yScale: PropTypes.func,
  innerWidth: PropTypes.number,
};
