import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import { LoadSpinner } from './CommonUIs';
import logger from '../utils/logger';
import { Notification } from './Notification';
import SimpleBarReact from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import moment from 'moment-timezone';
moment.tz.setDefault('Asia/Taipei');

// Helper functions
function handleChartError(chart) {
  let error_id = chart.eventArgs[0].id;
  const errors = chart.google.visualization.errors;
  let container = errors.getContainer(error_id);
  errors.removeError(error_id);
  errors.removeAll(container);
  errors.addError(container, `未開盤`, '', {
    style: 'background: #414455; font-size: 16px; font-weight: bold;',
  });
}

function StwFitx({ data }) {
  let fitx_change = data.slice(-1)[0][1];
  let stw_change = data.slice(-1)[0][2];
  return (
    <div id="stw-fitx-chart" className="card">
      <h3
        className={`chart-title inline ${
          fitx_change >= 0 ? 'rising' : 'falling'
        }`}
      >
        台股指數 {fitx_change}%
      </h3>
      {'　'}
      <h3
        className={`chart-title inline ${
          stw_change >= 0 ? 'rising' : 'falling'
        }`}
      >
        摩台指數 {stw_change}%
      </h3>
      <Chart
        width="100%"
        height="92%"
        chartType="AreaChart"
        loader={<LoadSpinner />}
        data={data}
        options={{
          isStacked: false,
          hAxis: {
            textStyle: { color: '#FFFFFF' },
            gridlines: { color: '#534053' },
            maxTextLines: 1,
            maxAlternation: 1,
            textPosition: 'in',
          },
          vAxes: [
            {
              textStyle: { color: '#FFFFFF' },
              gridlines: { count: 4, color: '#534053' },
              minorGridlines: { count: 0 },
              baseline: 0,
              baselineColor: 'MediumVioletRed',
              textPosition: 'in',
            },
            {},
          ],
          chartArea: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
          backgroundColor: 'transparent',
          legend: {
            alignment: 'end',
            position: 'in',
            textStyle: { color: '#ffffff' },
          },
          lineWidth: 2,
          areaOpacity: 0.2,
          colors: ['#32B2FF', '#e90b52'],
        }}
        chartEvents={[
          {
            eventName: 'error',
            callback: (chart) => {
              handleChartError(chart);
            },
          },
        ]}
      />
    </div>
  );
}

StwFitx.propTypes = {
  data: PropTypes.array,
};

function GoogleSheetsMultipleLinesChart({
  name,
  google_sheets_url,
  volume_query,
}) {
  return (
    <div className="quarter-page-chart card">
      <Chart
        width="100%"
        height="38vh"
        chartType="LineChart"
        loader={<LoadSpinner />}
        spreadSheetUrl={google_sheets_url}
        spreadSheetQueryParameters={{
          headers: 1,
          query: volume_query,
        }}
        options={{
          title: name + '量能比較',
          titleTextStyle: {
            fontSize: 18,
            color: 'white',
          },
          backgroundColor: 'transparent',
          hAxis: {
            textStyle: { color: '#fefdfe' },
            textPosition: 'in',
            maxTextLines: 1,
            maxAlternation: 1,
          },
          vAxis: {
            textStyle: { color: '#FFFFFF' },
            gridlines: { count: 5, color: '#534053' },
            minorGridlines: { count: 0 },
            baseline: 0,
            baselineColor: '#534053',
            textPosition: 'in',
            format: 'short',
          },
          legend: { position: 'in', textStyle: { color: '#fefdfe' } },
          colors: ['#ed820f', 'lightslategray', '#e90b52'],
          lineWidth: 1.5,
          chartArea: {
            left: 0,
            right: 0,
            top: 40,
            bottom: 0,
          },
        }}
        chartEvents={[
          {
            eventName: 'error',
            callback: (chart) => {
              handleChartError(chart);
            },
          },
        ]}
      />
    </div>
  );
}

GoogleSheetsMultipleLinesChart.propTypes = {
  name: PropTypes.string,
  google_sheets_url: PropTypes.string,
  volume_query: PropTypes.string,
};

function GoogleSheetsDataTable({ google_sheets_url, volume_query }) {
  var cssClassNames = {
    headerRow: 'headerRow',
    tableRow: 'tableRow',
    oddTableRow: '',
    selectedTableRow: 'selectedTableRow',
    hoverTableRow: 'hoverTableRow',
    headerCell: 'headerCell',
    tableCell: 'tableCell',
    rowNumberCell: '',
  };
  return (
    <div className="table-chart">
      <SimpleBarReact>
        <Chart
          width={'100%'}
          height={'45vh'}
          chartType="Table"
          loader={<LoadSpinner />}
          spreadSheetUrl={google_sheets_url}
          spreadSheetQueryParameters={{
            headers: 1,
            query: volume_query,
          }}
          options={{
            showRowNumber: false,
            alternatingRowStyle: false,
            allowHtml: true,
            cssClassNames: cssClassNames,
          }}
          rootProps={{ 'data-testid': '1' }}
        />
      </SimpleBarReact>
    </div>
  );
}
GoogleSheetsDataTable.propTypes = {
  google_sheets_url: PropTypes.string,
  volume_query: PropTypes.string,
};

const GoogleSheetsMultipleBarsChart = ({
  name,
  google_sheets_url,
  wave_query,
}) => {
  return (
    <div className="card wave-comparison-bar">
      <h3 className="chart-title">{name}震幅</h3>
      <Chart
        width={'100%'}
        height={'90%'}
        chartType="ColumnChart"
        loader={<LoadSpinner />}
        spreadSheetUrl={google_sheets_url}
        spreadSheetQueryParameters={{
          headers: 1,
          query: wave_query,
        }}
        options={{
          legend: { position: 'none' },
          titlePosition: 'none',
          backgroundColor: {
            fill: 'transparent',
          },
          chartArea: {
            top: 30,
            bottom: 40,
          },
          hAxis: {
            textStyle: { color: 'white', bold: true, fontSize: 10 },
            textPosition: 'bottom',
          },
          vAxis: {
            textStyle: { color: '#fefdfe' },
            gridlines: { count: 5, color: '#534053' },
            minorGridlines: { count: 0 },
            baseline: 0,
            baselineColor: '#534053',
            format: 'decimal',
          },
          colors: ['#e90b52'],
        }}
        // For tests
        rootProps={{ 'data-testid': '2' }}
      />
    </div>
  );
};
GoogleSheetsMultipleBarsChart.propTypes = {
  name: PropTypes.string,
  google_sheets_url: PropTypes.string,
  wave_query: PropTypes.string,
};

function LineChart({ data, title, baseline }) {
  // TODO: Add data time
  const lineData = data.map((d) => [moment(d[0]).format('HH:mm'), d[1]]);
  lineData.unshift(['時間', '價位']);
  let current_price = data.slice(-1)[0][1];
  let change = ((current_price - baseline) / baseline) * 100;
  return (
    <div className="line-chart card flex-card">
      <h3
        className={`chart-title inline ${change >= 0 ? 'rising' : 'falling'}`}
      >
        {title} {current_price ? current_price.toFixed(2) : '無最新價'}
        <span
          className={`sm-txt-on-bg ${
            change >= 0 ? 'bullish-bg' : 'bearish-bg'
          }`}
        >
          {change >= 0 ? `+${change.toFixed(2)}` : change.toFixed(2)}%
        </span>
        {/* <span className="sm-txt-on-bg"> */}
        {/*   {moment(data[data.length - 1][0]).format("MM/DD")} */}
        {/* </span> */}
      </h3>
      <Chart
        width="100%"
        height="110px"
        chartType="LineChart"
        loader={<LoadSpinner />}
        data={lineData}
        options={{
          backgroundColor: 'transparent',
          hAxis: {
            textStyle: { color: '#fefdfe' },
            gridlines: { color: '#534053' },
            textPosition: 'in',
            maxTextLines: 1,
            maxAlternation: 1,
          },
          vAxis: {
            textStyle: { color: '#FFFFFF' },
            gridlines: { count: 3, color: '#534053' },
            minorGridlines: { count: 0 },
            baseline: baseline,
            baselineColor: 'MediumVioletRed',
            textPosition: 'in',
          },
          legend: { position: 'none' },
          colors: ['#ed820f'],
          lineWidth: 1,
          chartArea: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        }}
        chartEvents={[
          {
            eventName: 'error',
            callback: (chart) => {
              handleChartError(chart);
            },
          },
        ]}
      />
    </div>
  );
}

LineChart.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  baseline: PropTypes.number,
};

const WantGooLineChart = ({ category, symbol, title }) => {
  const [lineData, setLineData] = useState(null);
  const [infoData, setInfoData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const transformFn = useCallback((data) => {
    const result = data.map((obj) => [
      moment(obj.time).format('HH:mm'),
      obj.close,
    ]);
    result.unshift(['時間', '價位']);
    return result;
  }, []);

  useEffect(() => {
    // 早盤、夜盤需要不同的timestamp
    const getTimestamp = () => {
      if (moment().hours() >= 15) {
        return moment(moment().hours(7)).valueOf();
      } else if (moment().hours() < 8) {
        return moment(moment().hours(0).subtract(1, 'days')).valueOf();
      } else {
        return moment(moment().format('YYYY-MM-DD')).valueOf();
      }
    };

    // Info用來抓前日收盤價，data抓當日行情
    let INFO_API_URL, DATA_API_URL;
    category === 'global'
      ? (INFO_API_URL = `https://www.wantgoo.com/${category}/${symbol}/info`)
      : (INFO_API_URL = `https://www.wantgoo.com/${category}/${symbol}/commoditystate`);
    category === 'global'
      ? (DATA_API_URL = `https://www.wantgoo.com/${category}/${symbol}/realtimeprice-pricemin1?equalandafter=${getTimestamp()}`)
      : (DATA_API_URL = `https://www.wantgoo.com/${category}/${symbol}/realtimeprice?equalandafter=${getTimestamp()}`);

    const CORS = 'https://morning-wave-49482.herokuapp.com/';
    const fetchData = async () => {
      let fetchLineData = fetch(CORS + DATA_API_URL)
        .then((response) => response.json())
        .catch((err) => {
          setIsError(true);
          logger.error(err);
        });
      let fetchInfoData = fetch(CORS + INFO_API_URL)
        .then((response) => response.json())
        .catch((err) => {
          setIsError(true);
          logger.error(err);
        });
      // 兩個API，都得抓到才算成功
      Promise.all([fetchLineData, fetchInfoData])
        .then((values) => {
          setLineData(transformFn(values[0]));
          setInfoData(values[1]);
          setIsLoading(false);
          setIsError(false);
        })
        .catch((error) => {
          setIsError(true);
          logger.error(error);
        });
    };

    fetchData();

    // 每分鐘抓一次
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => clearInterval(interval);
  }, [transformFn, category, symbol]);

  return (
    <>
      {isError && (
        <Notification status="error" text={`資料可能有誤，請稍候。`} />
      )}
      {isLoading ? (
        <LoadSpinner />
      ) : (
        <>
          <LineChart
            data={lineData}
            title={title}
            baseline={category === 'global' ? infoData.last : infoData.flat}
          />
        </>
      )}
    </>
  );
};

WantGooLineChart.propTypes = {
  category: PropTypes.string,
  symbol: PropTypes.string,
  title: PropTypes.string,
};

export {
  // StwFitx,
  GoogleSheetsMultipleLinesChart,
  GoogleSheetsDataTable,
  GoogleSheetsMultipleBarsChart,
  // WantGooLineChart,
};
