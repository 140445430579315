import { useEffect, useRef } from 'react';

// Dan's useInterval hook https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let isMounted = true;
    function tick() {
      savedCallback.current();
    }
    if (delay !== null && isMounted) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return () => {
      isMounted = false;
    };
  }, [delay]);
};
