import React from 'react';
import { LoadSpinner } from '../CommonUIs';
import { Chart } from 'react-google-charts';
import { useCBCData } from './useCBCData';

export const M1BvsM2Chart = () => {
  const rawData = useCBCData();
  if (!rawData) return <LoadSpinner />;
  const data = transform(rawData);

  return (
    <Chart
      width="100%"
      height="38vh"
      chartType="LineChart"
      loader={<LoadSpinner />}
      data={data}
      options={{
        title: '台灣資金動能：M1B vs M2',
        titleTextStyle: {
          fontSize: 18,
          color: 'white',
        },
        backgroundColor: 'transparent',
        hAxis: {
          textStyle: { color: '#fefdfe' },
          textPosition: 'in',
          maxTextLines: 1,
          maxAlternation: 1,
        },
        vAxis: {
          textStyle: { color: '#FFFFFF' },
          gridlines: { count: 5, color: '#534053' },
          minorGridlines: { count: 0 },
          baseline: 0,
          baselineColor: '#534053',
          textPosition: 'in',
          format: 'short',
        },
        legend: { position: 'in', textStyle: { color: '#fefdfe' } },
        colors: ['#ed820f', '#e90b52'],
        lineWidth: 1.5,
        chartArea: {
          left: 0,
          right: 0,
          top: 40,
          bottom: 0,
        },
      }}
      chartEvents={[
        {
          eventName: 'error',
          callback: (chart) => {
            handleChartError(chart);
          },
        },
      ]}
    />
  );
};

function transform(rawData) {
  const header = ['期間', 'M2年增率', 'M1B年增率'];
  const result = rawData.map((obj) => [
    obj['期間'],
    obj['貨幣總計數 -Ｍ２-年增率'] === '-'
      ? 0
      : Number(obj['貨幣總計數 -Ｍ２-年增率']),
    obj['貨幣總計數 -Ｍ１Ｂ-年增率'] === '-'
      ? 0
      : Number(obj['貨幣總計數 -Ｍ１Ｂ-年增率']),
  ]);
  return [header, ...result];
}

function handleChartError(chart) {
  let error_id = chart.eventArgs[0].id;
  const errors = chart.google.visualization.errors;
  let container = errors.getContainer(error_id);
  errors.removeError(error_id);
  errors.removeAll(container);
  errors.addError(container, `未開盤`, '', {
    style: 'background: #414455; font-size: 16px; font-weight: bold;',
  });
}
