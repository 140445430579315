import { useState, useEffect } from 'react';
import moment from 'moment';
import { logger } from '../../utils/logger';
import { fetchAndBypassCORS } from '../../utils/DataFetcher';

const DATA_API_URL =
  'http://fingfx.thomsonreuters.com/gfx/rngs/GLOBAL-ECONOMY-CENBANKS/010041YC47J/assets.json';

export const useReutersData = () => {
  const [data, setData] = useState();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) updateData();
    return () => {
      isMounted = false;
    };
  }, []);

  async function updateData() {
    try {
      const data = await fetchAndBypassCORS(DATA_API_URL);
      const cleanData = transform(data);
      setData(cleanData);
    } catch (error) {
      logger.error('Central banks data error: ', error.message);
      setData(null);
    }
  }

  return data;
};

const transform = (data) => {
  const dates = data.DataResponse.Dates;
  const result = dates.map((item, index) => [
    moment(item).format('MMM YYYY'),
    data.DataResponse.DataTypeValues[0].SymbolValues[0].Value[index],
    data.DataResponse.DataTypeValues[0].SymbolValues[1].Value[index],
    data.DataResponse.DataTypeValues[0].SymbolValues[2].Value[index],
    data.DataResponse.DataTypeValues[0].SymbolValues[3].Value[index],
    data.DataResponse.DataTypeValues[0].SymbolValues[4].Value[index],
  ]);
  result.unshift(['時間', '美聯準', '歐洲', '英國', '日本', '瑞士']);
  return result;
};
