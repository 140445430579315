import React from 'react';
import { XMarkerLine } from './XMarkerLine';
import styles from './LineChart.module.css';
import PropTypes from 'prop-types';

export const Tooltip = ({
  activeData,
  xScale,
  innerHeight,
  lineGenerator,
  xTooltipFormat,
}) => (
  <>
    <XMarkerLine
      value={activeData.date}
      xScale={xScale}
      innerHeight={innerHeight}
    />

    <g
      transform={`translate(${lineGenerator.x()(
        activeData
      )}, ${lineGenerator.y()(activeData)})`}
    >
      <circle className={styles.dataPoint} r={5} />
    </g>

    <g transform={`translate(${lineGenerator.x()(activeData)}, ${12})`}>
      <text className={styles.tooltipStroke} textAnchor={'middle'}>
        <tspan x="0" dy="0">
          {activeData.quote && activeData.quote.toFixed(2)}
        </tspan>
        <tspan x="0" dy={innerHeight}>
          {xTooltipFormat(activeData.date)}
        </tspan>
      </text>

      <text className={styles.tooltip} textAnchor={'middle'} x={0} y={0}>
        <tspan x="0" dy="0">
          {activeData.quote && activeData.quote.toFixed(2)}
        </tspan>
        <tspan x="0" dy={innerHeight}>
          {xTooltipFormat(activeData.date)}
        </tspan>
      </text>
    </g>
  </>
);

Tooltip.propTypes = {
  activeData: PropTypes.object,
  xScale: PropTypes.func,
  innerHeight: PropTypes.number,
  lineGenerator: PropTypes.func,
  xTooltipFormat: PropTypes.func,
};
