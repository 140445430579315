import React from 'react';
import { LoadSpinner } from '../CommonUIs';
import { Chart } from 'react-google-charts';
import PropTypes from 'prop-types';

export function GoogleTreeMap({ data }) {
  return (
    <Chart
      width={'100%'}
      height={'100%'}
      chartType="TreeMap"
      loader={<LoadSpinner />}
      data={data}
      options={{
        highlightOnMouseOver: false,
        headerColor: '#503e55',
        noColor: '#414455',
        midColor: '#414455',
        minColor: '#00D75E',
        maxColor: '#FF0006',
        maxColorValue: 2.5,
        minColorValue: -2.5,
        showScale: false,
        textStyle: {
          color: 'White',
          fontSize: 14,
        },
      }}
      // chartEvents={[
      //   {
      //     eventName: 'error',
      //     callback: (chart) => {
      //       handleChartError(chart);
      //     },
      //   },
      // ]}
    />
  );
}

GoogleTreeMap.propTypes = {
  data: PropTypes.array,
};

function handleChartError(chart) {
  let error_id = chart.eventArgs[0].id;
  const errors = chart.google.visualization.errors;
  let container = errors.getContainer(error_id);
  errors.removeError(error_id);
  errors.removeAll(container);
  errors.addError(container, `未開盤`, '', {
    style: 'background: #414455; font-size: 16px; font-weight: bold;',
  });
}
