import React from 'react';
import { useReutersData } from './useReutersData';
import { GoogleStackedAreaChart } from './GoogleStackedAreaChart';
import { LoadSpinner } from '../CommonUIs';

export const CentralBankBlalanceSheetsLineChart = () => {
  const data = useReutersData();
  if (!data) return <LoadSpinner />;

  return (
    <div className="quarter-page-chart card">
      <GoogleStackedAreaChart title={'各大央行資產負債表'} data={data} />
    </div>
  );
};
