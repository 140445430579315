import React, { useReducer } from 'react';
import { useInterval } from '../utils/useInterval';
import {
  GoogleSheetsMultipleLinesChart,
  GoogleSheetsDataTable,
  GoogleSheetsMultipleBarsChart,
} from '../elements/Charts';

const GOOGLE_SHEET_SOURCES = [
  {
    name: '加權指數',
    google_sheets_url:
      'https://docs.google.com/spreadsheets/d/1XB8BiafYEWT4sC2KBpu842IMwrZCxQ5BKTplv47kCPk/edit?',
    volume_query: 'SELECT A, B, C, D LIMIT 54',
    wave_query: 'SELECT E, F LIMIT 3',
  },
  {
    name: '台指期',
    google_sheets_url:
      'https://docs.google.com/spreadsheets/d/1XB8BiafYEWT4sC2KBpu842IMwrZCxQ5BKTplv47kCPk/edit?',
    volume_query: 'SELECT G, H, I, J',
    wave_query: 'SELECT K, L LIMIT 3',
  },
];

const VolumeView = () => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const update_interval = 60000;
  useInterval(() => forceUpdate(), update_interval);

  return (
    <>
      {GOOGLE_SHEET_SOURCES.map((source) => (
        <div className="volume-charts" key={source.name}>
          <GoogleSheetsMultipleLinesChart {...source} />
          <div className="table-and-wave">
            <GoogleSheetsDataTable {...source} />
            <GoogleSheetsMultipleBarsChart {...source} />
          </div>
        </div>
      ))}
    </>
  );
};

export { VolumeView };
